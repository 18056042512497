import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpenSideBar: false,
  isOpenContactAdmin: false,
  isOpenSendInquiry: false,
  isOpenBusinessIntroduce: false,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    openSideBar: (state) => {
      state.isOpenSideBar = true;
    },
    closeSideBar: (state) => {
      state.isOpenSideBar = false;
    },
    openContactAdmin: (state) => {
      state.isOpenContactAdmin = true;
    },
    closeContactAdmin: (state) => {
      state.isOpenContactAdmin = false;
    },
    openSendInquiry: (state) => {
      state.isOpenSendInquiry = true;
    },
    closeSendInquiry: (state) => {
      state.isOpenSendInquiry = false;
    },
    openBusinessIntroduce: (state) => {
      state.isOpenBusinessIntroduce = true;
    },
    closeBusinessIntroduce: (state) => {
      state.isOpenBusinessIntroduce = false;
    },
  }
});

export const {
  openSideBar,
  closeSideBar,
  closeContactAdmin,
  openContactAdmin,
  openBusinessIntroduce,
  closeBusinessIntroduce,
  openSendInquiry,
  closeSendInquiry,
} = layoutSlice.actions;

export default layoutSlice.reducer;
