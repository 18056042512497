import { Divider, Row, Space, Typography } from 'antd';
import { HelpCircle } from 'react-feather';
import { Content } from 'antd/es/layout/layout';
import React, { useCallback, useEffect, useState } from 'react';
import HeaderFT2 from '../../../common/layout/HeaderFT2';
import RefundList from './RefundList';
import { REFUND_TYPE } from './constants';
import { CURRENCIES } from '../../../common/utilities/constants';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { formatCurrency } from '../../../utility/utils';
import { fetchRefundAggs } from '../../../common/api';
import ApplyTransferButton from './ApplyTransferButton';
import CurrencySelect from '../../features/common/components/CurrencySelect';
const { Title } = Typography;

const RefundListPage = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const refundType = REFUND_TYPE[type];
  const [searchParams, setSearchParams] = useSearchParams();
  const [currencyId, setCurrencyId] = useState(+searchParams.get('currency_id'));
  const [currency, setCurrency] = useState();
  const [total, setTotal] = useState({});
  const types = searchParams.getAll('types');

  const getRefundAggs = useCallback(async () => {
    try {
      const agg = await fetchRefundAggs({ currency_id: currencyId });
      switch (type) {
        case 'added':
          setTotal(agg.remaining || 0);
          break;
        default:
          setTotal(agg[type]);
          break;
      }
      setCurrency(CURRENCIES[agg.currency?.name]);
    } catch (error) {
      console.error(error);
    }
  }, [currencyId]);

  useEffect(() => {
    if (!refundType) navigate('/refund');
  }, [refundType]);

  useEffect(() => {
    getRefundAggs();
  }, [currencyId]);

  return (
    <>
      <HeaderFT2 title={refundType?.title} onNavigate={() => navigate('/refund')} />
      <Space direction="vertical" style={{ backgroundColor: '#F2F4F7', marginBottom: '108px' }}>
        <Content className="p-3 " style={{ backgroundColor: 'white' }}>
          <Row>
            <CurrencySelect
              selectedCurrency={currencyId}
              setSelectedCurrency={setCurrencyId}
              onChange={(value) => setSearchParams({ currency_id: value })}
              style={{ width: '100%', marginBottom: '24px' }}
            />
          </Row>
          <Row justify="space-between">
            <Title level={5}>{refundType?.title}</Title>
            {![REFUND_TYPE.added.value, REFUND_TYPE.expected.value].includes(type) && <HelpCircle size={20} />}
          </Row>
          <Divider style={{ margin: '8px 0' }} />
          <Row justify="end">
            <Title level={4}>
              {formatCurrency(total, currency?.scale)}
              {currency?.label}
            </Title>
          </Row>
        </Content>
        <Content className="p-3" style={{ backgroundColor: 'white' }}>
          <RefundList
            type={type !== REFUND_TYPE['added'].value ? type : undefined}
            types={types}
            maxHeight={650}
            currencyId={currencyId}
          />
        </Content>
      </Space>
      {type === REFUND_TYPE.added.value && <ApplyTransferButton withDrawer />}
    </>
  );
};

export default RefundListPage;
