import { forEach } from 'lodash';
import apiClient from '../../services/axios';

export const fetchRefunds = async (params) => {
  const response = await apiClient.get('/refund', { params });
  if (response.status === 200) {
    // Temporary
    response.data.remaining = Math.max(response.data.remaining, 0);
    return response.data;
  }
  return null;
};

export const fetchRefundAggs = async (params) => {
  const response = await apiClient.get('/refund/agg', { params });
  if (response.status === 200) {
    // Temporary
    // response.data.remaining = Math.max(response.data.remaining, 0) - (response?.data?.shipping_cost || 0);
    return response.data;
  }
  return null;
};
