import { Row, Col, Typography, Button, message, Spin, Anchor, Collapse } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { X } from 'react-feather';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HeaderFT2 from '../../../common/layout/HeaderFT2';
import ActionIcon from '../../../common/components/ActionIcons';
import { useSelector } from 'react-redux';
import { COUNTRIES, CURRENCIES, USER_ROLE } from '../../../common/utilities/constants';
import apiClient from '../../../services/axios';
import { useEffect, useState } from 'react';
import { formatCurrency } from '../../../utility/utils';
import dayjs from 'dayjs';
import './style.scss';

const { Text } = Typography;

const RefundRequestCompleted = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const requestId = searchParams.get('id');
  const [data, setData] = useState(null);
  const { userInfo } = useSelector((state) => state.auth);
  const [currency, setCurrency] = useState(CURRENCIES.EUR);
  const navigateRefundRequestPage = () => {
    navigate('/refund-request');
  };

  useEffect(() => {
    if (requestId) {
      fetchRequest(requestId);
    }
  }, [requestId]);

  const fetchRequest = async (id) => {
    try {
      const { data } = await apiClient.get(`/refund-request/${id}`);
      if (data) {
        setData(data);
        setCurrency(COUNTRIES[data?.country]?.currency || CURRENCIES.EUR);
      } else {
        message.error('error');
      }
    } catch (error) {
      message.error(error.response?.data?.message || 'error');
    }
  };

  return (
    <>
      <HeaderFT2
        title={'신청서 접수 완료'}
        rightIems={<ActionIcon icon={<X />} onClick={navigateRefundRequestPage} />}
        isHideLeftIcon
      />

      <Content
        className="d-flex flex-column justify-content-between request-completed"
        style={{
          minHeight: 380,
          backgroundColor: '#fff',
          padding: '96px 19px 15px',
          fontWeight: 500
        }}>
        <div>
          <Row>
            <Col span={24} className="d-flex justify-content-center">
              {/* <CheckedIcon /> */}
              <img
                src={process.env.PUBLIC_URL + '/completed_icon.png'}
                // style={{ width: '229px', marginTop: '20px', marginLeft: 'calc(50% - 115px)' }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} className="d-flex justify-content-center">
              <h3 className="gray-700 text-20 my-4">택스리펀 배송대행 신청 완료</h3>
            </Col>
          </Row>
          {userInfo?.role === USER_ROLE.FreeTCustomer && (
            <>
              <Row>
                <Col span={24} className="text-16">
                  해외 배송비는{' '}
                  <Text strong type="danger" className="text-16">
                    환급 예정 금액
                  </Text>
                  에서
                  <Text strong type="danger" className="text-16">
                    자동 차감
                  </Text>
                  됩니다
                </Col>
              </Row>
            </>
          )}
          <div
            className="p-2 my-2"
            style={{ backgroundColor: '#F8F9FC', border: '1px solid #B3B2FE', borderRadius: '4px' }}>
            <Row justify="space-between" style={{lineHeight: '38px', height: '38px'}}>
              <Col span={12} className="text-16">
                환급 예정 금액
              </Col>
              <Col span={12} className="text-20" style={{ color: '#3431D6', textAlign: 'right' }}>
                {formatCurrency(data?.total_est_refund_amount || 0, currency.scale)}
                {currency.label}
              </Col>
            </Row>
            {/* <Row justify="space-between mt-2">
              <Col span={12} className="text-16">
                환급 예정일
              </Col>
              <Col span={12} className="text-16" style={{ textAlign: 'right' }}>
                {data?.updated_at ? dayjs(data?.updated_at).add(1, 'month').endOf('month').format('YYYY년 M월 DD일') : ''}
              </Col>
            </Row> */}
          </div>
          <div
            className="p-2 my-2"
            style={{ backgroundColor: '#F8F9FC', border: '1px solid #B3B2FE', borderRadius: '4px' }}>
            <Row justify="space-between">
              <Col span={24} className="text-16">
                환급 예정 날짜
              </Col>
            </Row>
            <div className="mt-2">
              일반적으로 현지 세무서에 환급 신고 후 약 4~6주 후에 환급이 완료되며, 바캉스, 연말 연초 등 장기 휴무가 있는
              월에는 1~2달 추가 지연될 수 있습니다. <br /> 자세한 내용은{' '}
              <a
                target={'_blank'}
                rel="noreferrer"
                href={`https://hautevie.notion.site/FAQ-d795297ce98142d2a25ee870b1d41d48`}>
                FAQ 메뉴
              </a>
              에서 확인할 수 있습니다.
            </div>
          </div>
          <Collapse
            style={{
              borderRadius: '4px',
            }}
            items={[
              {
                key: '1',
                label: <span className="text-16">유의 사항</span>,
                children: (
                  <ul style={{ margin: '0' }}>
                    <li>정책에 위반되는 사항 및 현지 세무서의 판단으로 환급이 거절 또는 지연될 수 있습니다.</li>
                    <li>부가가치세 크레딧의 수령 시간은 변동될 수 있으며 프랑스 세무 관리의 권한입니다.</li>
                    <li>프리티고는 세금 환급 국가 기관이 아님을 명시합니다.</li>
                  </ul>
                )
              }
            ]}
            // defaultActiveKey={['1']}
            expandIconPosition="right"
          />
        </div>
        <div style={{marginBottom: '50px'}}>
          <Button
            onClick={navigateRefundRequestPage}
            // className="fixed-bottom-btn"
            block
            type="primary"
            disabled={false}
            className="text-18"
            style={{
              backgroundColor: '#3431D6',
              color: '#FFFFFF',
              fontWeight: 500,
              height: '48px',
              boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.24)'
            }}>
            메인 화면으로 가기
          </Button>
        </div>
      </Content>
    </>
  );
};

export default RefundRequestCompleted;
