import { Button, Drawer, Input, message } from 'antd';
import React, { useState } from 'react';
import { X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { closeContactAdmin } from '../../redux/reducers/layoutSlice';
import { apiClientV2 } from '../../services/axios';

const ContactAdmin = () => {
  const dispatch = useDispatch();
  const { isOpenContactAdmin } = useSelector((state) => state.layout);
  const [value, setValue] = useState();

  const hanldeContactAdmin = async () => {
    try {
      const res = await apiClientV2.post('/message', {
        content: value
      });
      if (res.status === 201) {
        setValue('');
        message.success('Successfully');
        dispatch(closeContactAdmin());
      } else {
        message.error(res?.data?.message || 'Error');
      }
    } catch (error) {
      message.error(error?.response?.data?.message || 'Error');
    }
  };
  return (
    <Drawer
      title={false}
      placement={'bottom'}
      closable={false}
      onClose={() => dispatch(closeContactAdmin())}
      open={isOpenContactAdmin}
      rootClassName="optiosDrawer"
      contentWrapperStyle={{
        overflow: 'scroll'
      }}>
      <div className="d-flex justify-content-between">
        <p
          style={{
            fontSize: '16px',
            color: '#101828',
            fontWeight: '700'
          }}>
          문의 하기
        </p>
        <X style={{ marginBottom: '20px', fontSize: '18px' }} onClick={() => dispatch(closeContactAdmin())} />
      </div>
      <div>
        <Input.TextArea rows={3} placeholder={`메시지를 입력해 주세요`} onChange={(e) => setValue(e.target.value)} value={value} />
        <Button type="primary" block disabled={!value} size="large" className="mt-4" onClick={hanldeContactAdmin}>
          전송
        </Button>
      </div>
    </Drawer>
  );
};

export default ContactAdmin;
