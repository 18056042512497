import { Tabs } from 'antd';
import React from 'react';
import RefundList from './RefundList';
import { REFUND_TYPE } from './constants';

const RefundTabs = ({ setMemo, setOpenMemo, currencyId }) => {
  const TabChildren = (props) => (
    <RefundList setMemo={setMemo} setOpenMemo={setOpenMemo} currencyId={currencyId} {...props} />
  );
  const refundDetailTabs = [
    {
      key: 'all',
      label: '전체',
      children: <TabChildren />
    },
    {
      key: 'added',
      label: '환급',
      children: <TabChildren 
      // type={REFUND_TYPE.added.value}
      types={[REFUND_TYPE.added.value, REFUND_TYPE.shipping_cost.value]}
       />
    },
    {
      key: 'deducted',
      label: '이체',
      children: <TabChildren type={REFUND_TYPE.deducted.value} />
    },
    {
      key: 'expected',
      label: '예정',
      children: <TabChildren type={REFUND_TYPE.expected.value} />
    }
  ];

  return <Tabs defaultActiveKey={refundDetailTabs[0].key} items={refundDetailTabs} />;
};

export default RefundTabs;
