export const REFUND_TYPE = {
  added: {
    value: 'added',
    display: '환급',
    title: '이체 가능 환급금',
    description: '환급 완료 (이체 가능)',
    color: '#4BBF9F',
    sign: '+'
  },
  expected: {
    value: 'expected',
    display: '환급 예정',
    title: '적립 예정 환급금',
    description: '환급금',
    color: '#EAAA08',
    sign: ''
  },
  deducted: {
    value: 'deducted',
    display: '이체 완료',
    description: '계좌 이체',
    color: '#F63D68',
    sign: '-'
  },
  shipping_cost: {
    value: 'shipping_cost',
    display: '배송비',
    description: '배송비',
    color: '#F63D68',
    sign: '-'
  },
  transfer_scheduled: {
    value: 'transfer_scheduled',
    display: '이체 예정',
    description: '이체 예정',
    color: '#EAAA08',
    sign: ''
  }
};
