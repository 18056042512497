import { Button, Divider, Drawer, Input, Space, Spin, Typography } from 'antd';
import { HelpCircle, X } from 'react-feather';
import { Content } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import HeaderFT from '../../../common/layout/HeaderFT';
import RefundAggList from './RefundAggList';
import RefundTabs from './RefundTabs';
import ApplyTransferButton from './ApplyTransferButton';
import InformationDrawer from '../../../common/components/InformationDrawer';
import CurrencySelect from '../../features/common/components/CurrencySelect';
import { CURRENCIES } from '../../../common/utilities/constants';

const { Paragraph, Title } = Typography;

const RefundPage = () => {
  const [openMemo, setOpenMemo] = useState(false);
  const [memo, setMemo] = useState('');
  const [openInfoDrawer, setOpenInfoDrawer] = useState(false);
  const [currencyId, setCurrencyId] = useState(null);

  return (
    <>
      <HeaderFT title={'환급금'} />
      <Space direction="vertical" size="middle" style={{ marginBottom: '108px', backgroundColor: '#F2F4F7' }}>
        <Content className="p-3" style={{ backgroundColor: 'white' }}>
          <CurrencySelect selectedCurrency={currencyId} setSelectedCurrency={setCurrencyId} style={{ width: '100%' }} />
          <RefundAggList currencyId={currencyId} />
        </Content>
        <Content className="p-3 mb-3" style={{ backgroundColor: 'white' }}>
          <div className="d-flex justify-content-between">
            <Title level={4}>환급금 내역</Title>
            <Button type="text" shape="circle" icon={<HelpCircle />} onClick={() => setOpenInfoDrawer(true)} />
          </div>
          <Divider className="my-2" />
          <RefundTabs setMemo={setMemo} setOpenMemo={setOpenMemo} currencyId={currencyId} />
        </Content>
      </Space>
      <ApplyTransferButton withDrawer />
      <Drawer
        title={false}
        placement={'bottom'}
        closable={false}
        onClose={() => {
          setOpenMemo(false);
          setMemo('');
        }}
        open={openMemo}
        contentWrapperStyle={{
          height: '200px',
          overflow: 'scroll'
        }}>
        <div className="d-flex justify-content-between">
          <p
            style={{
              fontSize: '16px',
              color: '#101828',
              fontWeight: '700'
            }}>
            메모
          </p>
          <X
            style={{ marginBottom: '20px', fontSize: '18px' }}
            onClick={() => {
              setOpenMemo(false);
              setMemo('');
            }}
          />
        </div>
        <div>
          <Input.TextArea rows={3} placeholder={`Memo`} value={memo} readOnly />
        </div>
      </Drawer>
      <InformationDrawer
        title="환급금"
        content={
          <Paragraph>
            <Title level={5}>이체 가능 환급금</Title>
            <ul>
              <li>현재 이체할 수 있는 환급금입니다</li>
            </ul>
            <Title level={5}>적립 예정 환급금</Title>
            <ul>
              <li>인보이스 신청 후 배송 완료 전 환급금입니다</li>
            </ul>
            <Title level={5}>총 받은 환급금</Title>
            <ul>
              <li>총 받은 환급금입니다</li>
            </ul>
          </Paragraph>
        }
        open={openInfoDrawer}
        onClose={() => setOpenInfoDrawer(false)}
      />
    </>
  );
};

export default RefundPage;
