import { Button, Col, Typography, Form, Row, message, Card, Space } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import UploadFileCustom from '../../../common/components/UploadFile';
import apiClient from '../../../services/axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Content } from 'antd/es/layout/layout';

const { Title, Text } = Typography;

const BulkCreateRefundRequest = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const countryRequest = searchParams.get('country') || COUNTRIES.france.value;
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState(false);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const onFinish = (values) => {
    values.bulk_excel = values.bulk_excel[0];
    values.bulk_excel.picture_type = 'bulk_excel';
    values.bulk_invoice_pictures.forEach((pic) => (pic.picture_type = 'bulk_invoice'));
    values.country = countryRequest;
    apiClient
      .post('/refund-request/upload', values)
      .then((res) => {
        if (res.status === 201) {
          navigate(`/refund-request/completed?id=${res?.data?.[0]?.id}`);
        } else {
          throw new Error();
        }
      })
      .catch(() => message.error('세금 환급 요청을 생성하지 못했습니다.'));
  };

  const downloadSample = () => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/public/documents/upload_refund_request_sample.xlsx`;
    apiClient.get(url, { responseType: 'blob' }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Sample.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <>
      <Content style={{ padding: '32px 16px 90px 16px', margin: 0, marginBottom: '70px' }}>
        <Space direction="vertical" size={14} style={{ width: '100%' }}>
          <Row justify="space-between">
            <Title level={4}>제품 정보</Title>
            <Text>
              <span style={{ color: 'red' }}>*</span> 필수
            </Text>
          </Row>
          <Form
            name="bulk-create-refund-request"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{
              bulk_excel: [],
              memo: '',
              bulk_invoice_pictures: [],
              country: countryRequest,
            }}>
            <Card bodyStyle={{ display: 'flex', justifyContent: 'center', padding: '16px 0px' }}>
              <Space direction="vertical" size="small" style={{ width: '100%', padding: '0px 16px' }}>
                {/* Upload XLSX file */}
                <Form.Item label="신청서" name="bulk_excel" rules={[{ required: true, message: '신청서를 선택하' }]}>
                  <UploadFileCustom
                    multiple={false}
                    maxCount={1}
                    accept=".xlsx"
                    description="엑셀 파일 업로드"
                    upload_hint="XLSX"
                  />
                </Form.Item>
                <Button type="primary" color="#10264D" onClick={downloadSample} block style={{ marginBottom: '8px' }}>
                  부가세 환급 신청서 대량등록 양식 다운로드
                </Button>
                {/* Upload Invoice pictures */}
                <Form.Item
                  label={
                    <Col>
                      <Row className="text-14 gray-900 fw-5">구매 인보이스</Row>
                      <Row className="text-14 gray-900" style={{ color: 'red', fontSize: '12px' }}>
                        인보이스 파일명은 한국행 운송장번호와 일치시켜 주세요
                      </Row>
                    </Col>
                  }
                  name="bulk_invoice_pictures">
                  <UploadFileCustom
                    accept=".png,.jpg,.jpeg,.pdf"
                    description="인보이스 파일 업로드"
                    upload_hint="PDF or JPG or PNG..."
                  />
                </Form.Item>
                {/* Memo */}
                <Form.Item name="memo" label={<div className="text-14 gray-900 fw-5">메모</div>}>
                  <TextArea maxLength={200} style={{ height: 80, resize: 'none' }} placeholder="메모를 작성해 주세요" />
                </Form.Item>
              </Space>
            </Card>
          </Form>
        </Space>
      </Content>
      {/* Submit Button */}
      <div className="btn-bottom-container" style={{marginTop: '-120px'}}>
        <Button
          block
          type="primary"
          disabled={!submittable}
          style={!submittable ? {} : { backgroundColor: '#3431D6' }}
          onClick={() => form.submit()}>
          다음
        </Button>
      </div>
    </>
  );
};

export default BulkCreateRefundRequest;
