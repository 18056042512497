import { Button } from 'antd';
import React, { useState } from 'react';
import TransferDrawer from './TransferDrawer';

const ApplyTransferButton = ({ withDrawer = true, buttonProps, drawerProps }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="fixed-bottom-container">
        <Button
          onClick={() => setOpen(true)}
          style={{
            width: '100%',
            height: '48px',
            color: 'white',
            backgroundColor: '#3431D6',
            border: 'none',
            borderRadius: 4,
            fontWeight: '500',
            fontSize: 20
          }}
          {...buttonProps}>
          이체 신청
        </Button>
      </div>
      {withDrawer && (
        <TransferDrawer
          open={open}
          onSubmitted={() => setOpen(false)}
          onClose={() => setOpen(false)}
          {...drawerProps}
        />
      )}
    </>
  );
};

export default ApplyTransferButton;
