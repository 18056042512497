import React, { useCallback, useEffect, useState } from 'react';
import { List, Spin } from 'antd';
import { ChevronRight } from 'react-feather';
import { CURRENCIES } from '../../../common/utilities/constants';
import { fetchRefundAggs } from '../../../common/api';
import { formatCurrency } from '../../../utility/utils';
import { Link } from 'react-router-dom';

const RefundAggList = ({ currencyId }) => {
  const [loading, setLoading] = useState(false);
  const [refundAgg, setRefungAgg] = useState({});
  const currency = CURRENCIES[refundAgg.currency?.name];
  const aggregations = [
    {
      type: 'added', // Available Refund
      title: '이체 가능 환급금',
      href: '/refund/added?types=added&types=deducted&types=shipping_cost' + (currencyId ? `&currency_id=${currencyId}` : ''),
      amount: refundAgg.remaining || 0
    },
    {
      type: 'expected', // Expected Refund
      title: '적립 예정 환급금',
      href: '/refund/expected' + (currencyId ? `?currency_id=${currencyId}` : ''),
      amount: refundAgg.expected || 0
    },
    {
      type: 'total', // Total Refund
      title: '총 받은 환급금',
      amount: refundAgg.total || 0
    }
  ];

  const getRefundAggs = useCallback(async () => {
    try {
      setLoading(true);
      const agg = await fetchRefundAggs({ currency_id: currencyId });
      setRefungAgg(agg);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [currencyId]);

  useEffect(() => {
    getRefundAggs();
  }, [currencyId]);

  return (
    <Spin tip="Loading" spinning={loading}>
      <List
        dataSource={aggregations}
        renderItem={(item, _) => (
          <List.Item>
            <List.Item.Meta
              title={
                <Link
                  className="d-flex justify-content-between"
                  to={item.href}
                  style={{ textDecoration: 'none' }}>
                  {item.title}
                  <div className="d-flex">
                    <b style={{ color: 'black', textDecoration: item.href ? 'underline' : 'none' }}>
                      {formatCurrency(item.amount, currency?.scale)}
                      {currency?.label}
                    </b>
                    {item.href && <ChevronRight />}
                  </div>
                </Link>
              }
            />
          </List.Item>
        )}
      />
    </Spin>
  );
};

export default RefundAggList;
